<template>
  
  <section class="ftco-intro-2 img set-img" :style="{ backgroundImage: 'url(' + bg_cta + ')' }">
    <div class="overlay"
    :style="{ 'backgroundColor': overlayCTA}"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <div class="cta-content">
            <p v-html="tituloCTA"></p>
            <h2 class="mb-md-0 mb-4" v-html="resumoCTA"></h2>                
          </div>
        </div>
        <div class="col-md-4 col-lg-4 d-flex align-items-center">
          <p class="mb-0">
            <a :href="linkCTA" target="_blank" class="btn btn-white py-md-4 py-3 px-4">
              {{ btnCTA }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  data() {
    return {
      cta: [],
      bg_cta: '',
      overlayCTA: '',
      tituloCTA: '',
      resumoCTA: '',
      btnCTA: '',
      linkCTA: ''
    }
  },
  created() {
    //----------------- Informações 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/call%20action")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.cta = retornoAPI  
      this.bg_cta = this.$apiUrlIndex + this.cta[0].institucionalImg[0].urlImagem 
      this.overlayCTA  =this.cta[0].cor1    
      this.tituloCTA  =this.cta[0].nome    
      this.resumoCTA  =this.cta[0].resumo1    
      this.btnCTA  =this.cta[0].textoBtn1    
      this.linkCTA  =this.cta[0].linkBtn1   
    });
  }
}
</script>