<template>
  
  <section>

    <!-- Topo Título -->
    <div class="titulo-topo-internas pad-section bg-body-light">
      <div class="container-xl">
        <div class="row">
          <!--  data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" -->
          <div class="col text-center heading-section">
            <div class="text-center">
              <h2>{{ curriculoTitulo }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Faixa Negócio -->
    <div class="faixa-negocio py-1" style="background: #25486b;"></div>

    <!-- Form -->
    <div class="pad-section"
      :style="{ 'backgroundColor': curriculoCor_bg}"
    >
      <div class="container-xl">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="folha p-3 p-lg-5">
              <form class="form-curriculo">
                <div class="row">
                  <!-- Coluna 1 -->
                  <div class="col-md-6">
                    <!-- Nome -->
                    <input 
                      type="text" 
                      class="form-control mb-2" 
                      placeholder="Nome"
                      v-model="nomeCurriculo"
                    >
                    <!-- E-mail -->
                    <input 
                      type="email" 
                      class="form-control mb-2" 
                      placeholder="E-mail"
                      v-model="emailCurriculo"
                    >

                    <textarea 
                      class="form-control mb-2" 
                      id="#" 
                      placeholder="Que tipo de posição se interessa em competir?" rows="5"
                      v-model="textoCurriculo"
                    >
                    </textarea>

                    <!-- Radios -->
                    <textarea 
                      disabled class="form-control mb-2"       
                      placeholder="Tem algum segmento que não deseja trabalhar?"
                      rows="2"
                    >
                    </textarea>
                    <div class="d-flex">
                      <div class="custom-control custom-radio mr-30">
                        <input 
                          type="radio" 
                          id="id_radio1" 
                          name="customRadio" 
                          class="custom-control-input mr-10"
                          value="sim"
                          v-model="opt_radio1"
                        >
                        <label 
                          for="id_radio1"
                          class="custom-control-label" 
                        >
                          Sim
                        </label>
                      </div>

                      <div class="custom-control custom-radio">
                        <input 
                          type="radio" 
                          id="id_radio2" 
                          name="customRadio" 
                          class="custom-control-input mr-10"
                          value="nao"
                          v-model="opt_radio1"
                        >
                        <label 
                          class="custom-control-label" 
                          for="id_radio2"
                        >
                          Não
                        </label>
                      </div>
                    </div>
                    <!-- <span>Escolhido: {{ opt_radio1 }}</span> -->
                    <!-- Fim radios -->

                    <input 
                      type="text" 
                      class="form-control mb-2" 
                      placeholder="Informe"
                      v-model="informeRadio1"
                    >

                    <input 
                      type="text" 
                      class="form-control mb-2" 
                      placeholder="Tem mobilidade?"
                      v-model="mobilidade"
                    >
                    
                  </div>

                  <!-- Coluna 2 -->
                  <div class="col-md-6">
                    <input 
                      type="text" 
                      class="form-control mb-2" 
                      placeholder="Fone"
                      v-model="foneCurriculo"
                    >
                    <input 
                      type="text" 
                      class="form-control mb-2" 
                      placeholder="Cargo Pretendido"
                      v-model="cargoPretendido"
                    >

                    <!-- Radios 2 -->
                    <textarea 
                      disabled class="form-control mb-2"       
                      placeholder="Tem preferência por algum segmento?"
                      rows="2"
                    >
                    </textarea>
                                          
                    <div class="d-flex">
                      <div class="custom-control custom-radio mr-30">
                        <input 
                          type="radio" 
                          id="id_radio3" 
                          name="prefSegmento" 
                          class="custom-control-input mr-10"
                          value="sim"
                          v-model="opt_radio2"
                        >
                        <label class="custom-control-label" for="customRadio1">Sim</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input 
                          type="radio" 
                          id="id_radio4" 
                          name="prefSegmento" 
                          class="custom-control-input mr-10"
                          value="nao"
                          v-model="opt_radio2"
                        >
                        <label 
                          class="custom-control-label" 
                          for="customRadio2"
                        >
                          Não
                        </label>
                      </div>
                    </div>  
                    <!-- <span>Escolhido: {{ opt_radio2 }}</span> -->
                    <!-- Fim radios -->

                    <input 
                      type="text" 
                      class="form-control mb-2" 
                      placeholder="Informe"
                      v-model="informeRadio2"
                    >
                    <textarea 
                      class="form-control mb-2" 
                      placeholder="Qual perfil de empresa está buscando recolocação?"
                      rows="2"
                      v-model="perfilDaEmpresa"
                    >
                    </textarea>
                    
                    <textarea 
                      class="form-control mb-2" 
                      placeholder="Tem alguma região do Brasil que não considera opção?"
                      rows="2"
                      v-model="regiao"
                    >
                    </textarea>

                    <input 
                      type="text" 
                      class="form-control mb-2" 
                      placeholder="Expectativa salarial?"
                      v-model="salarioPretendido"
                    >
                  </div>

                  <!-- Anexar arquivo -->
                  <div class="col-md-6 mt-4">
                    <input 
                      class="mt-2" 
                      type="file" 
                      id="arquivo"
                    >
                    <div class="alert-warning mt-1 mb-2 px-3 py-1" role="alert">
                      <span class="form-alert">Anexar somente arquivos DOC ou PDF</span> 
                    </div>
                  </div>
                </div>

                <!-- Enviar -->
                <button 
                  type="button" 
                  @click="enviarDadosFormulario" 
                  class="btn btn-form mt-5"
                >
                  Enviar Currículo
                </button>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script>

export default {
  
  data() {
    return {
      menuServicos: [],
      curriculoCor_bg: '',
      curriculoTitulo: '',
      emailRecebeCurriculo: '',

      // Formulario ( Variáveis criadas para captura(v-model) dos dados preenchidos no form)
      nomeCurriculo: '',
      emailCurriculo: '',
      textoCurriculo: '',
      opt_radio1: '',
      informeRadio1: '',
      mobilidade: '',
      // 
      foneCurriculo: '',
      cargoPretendido: '',
      informeRadio2: '',
      opt_radio2: '',
      perfilDaEmpresa: '',
      regiao: '',
      salarioPretendido: '',
      
      // Objeto Contato
      Contato: {
        NomeEmpresa: "Kemp Consultoria",
        Assunto: "",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: "",
        Anexo: "",
        NomeAnexo: "",
        TipoAnexo: "",
      }
    }
  },
  created() {
    this.$http
    .get(this.$apiUrl + "/institucional/telas/dados")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.dadosCurriculo = retornoAPI   
      this.curriculoCor_bg = this.dadosCurriculo[0].cor1       
      this.curriculoTitulo = this.dadosCurriculo[0].nome       
      this.emailRecebeCurriculo = this.dadosCurriculo[0].referencia       

    });    
  },
  methods: {
    enviarDadosFormulario() {
      //Email que irá receber os dados do formulário preenchido (empresa site)
      this.Contato.Destinatario = this.emailRecebeCurriculo
      // this.Contato.Destinatario = "thiagokdma@gmail.com"

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.emailCurriculo

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = 
        "Nome:" + this.nomeCurriculo + 
        " <br /> E-mail: " + this.emailCurriculo +
        " <br /> Que tipo de posição se interessa em competir?: " + this.textoCurriculo + 
        " <br /> Tem algum segmento que não deseja trabalhar?: " + this.opt_radio1 + 
        " <br /> Informe: " + this.informeRadio1 + 
        // radio 1
        " <br /> Tem mobilidade?: " + this.mobilidade + 
        " <br /> Fone: " + this.foneCurriculo + 
        " <br /> Cargo Pretendido: " + this.cargoPretendido + 
        " <br /> Tem preferência por algum segmento?: " + this.opt_radio2 + 
        " <br /> Informe: " + this.informeRadio2 +
        // radio 2
        " <br /> Qual perfil de empresa está buscando recolocação?: " + this.perfilDaEmpresa + 
        " <br /> Tem alguma região do Brasil que não considera opção?: " + this.regiao + 
        " <br /> Salário Pretendido: " + this.salarioPretendido  
      
      // Aplicar (id="arquivo") no input file
      var arquivos = document.getElementById("arquivo").files

      //SE TIVER ANEXO:
      if (arquivos != null && arquivos.length > 0) {
        var arq = "";
        var reader = new FileReader();
        var arquivoAnexo = arquivos[arquivos.length - 1];
        reader.readAsDataURL(arquivoAnexo);
        reader.onload = () => {
          arq = reader.result;
          this.Contato.Anexo = arq.toString().split(",")[1];
          this.Contato.NomeAnexo = arquivoAnexo.name;
          this.Contato.TipoAnexo = arquivoAnexo.type;
          this.Contato.Assunto = "Currículo enviado";
          this.Contato.EmailResposta = this.emailCurriculo
          console.log(this.Contato);

          this.$http
          .post(this.$apiUrl + "/contato/enviamail", this.Contato)
          .then(
            (response) => {
              if (response.ok) alert("Enviado")
              this.nomeCurriculo= ''
              this.emailCurriculo= ''
              this.textoCurriculo= ''
              this.opt_radio1= ''
              this.informeRadio1= ''
              this.mobilidade= ''
              this.foneCurriculo= ''
              this.cargoPretendido= ''
              this.informeRadio2= ''
              this.opt_radio2= ''
              this.perfilDaEmpresa= ''
              this.regiao= ''
              this.salarioPretendido= ''
            },
            (error) => {
              alert(error.bodyText)
              this.nomeCurriculo= ''
              this.emailCurriculo= ''
              this.textoCurriculo= ''
              this.opt_radio1= ''
              this.informeRadio1= ''
              this.mobilidade= ''
              this.foneCurriculo= ''
              this.cargoPretendido= ''
              this.informeRadio2= ''
              this.opt_radio2= ''
              this.perfilDaEmpresa= ''
              this.regiao= ''
              this.salarioPretendido= ''
            }
          );
        };
      }
      else
      {
        //SE NAO TIVER ANEXO:
        this.Contato.Assunto = "Currículo enviado";
        this.Contato.EmailResposta = this.emailCurriculo
        console.log(this.Contato);
        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
            alert("Enviado")
            this.nomeCurriculo= ''
            this.emailCurriculo= ''
            this.textoCurriculo= ''
            this.opt_radio1= ''
            this.informeRadio1= ''
            this.mobilidade= ''
            this.foneCurriculo= ''
            this.cargoPretendido= ''
            this.informeRadio2= ''
            this.opt_radio2= ''
            this.perfilDaEmpresa= ''
            this.regiao= ''
            this.salarioPretendido= ''
            
        },error => {
            alert(error.bodyText)
            this.nomeCurriculo= ''
            this.emailCurriculo= ''
            this.textoCurriculo= ''
            this.opt_radio1= ''
            this.informeRadio1= ''
            this.mobilidade= ''
            this.foneCurriculo= ''
            this.cargoPretendido= ''
            this.informeRadio2= ''
            this.opt_radio2= ''
            this.perfilDaEmpresa= ''
            this.regiao= ''
            this.salarioPretendido= ''
        });
      }
    }
  }
}
</script>