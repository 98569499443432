<template>
  
  <section>

    <!-- Topo Título -->
    <div class="titulo-topo-internas pad-section bg-body-light">
      <div class="container-xl">
        <div class="row">
          <!--  data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" -->
          <div class="col text-center heading-section">
            <div class="text-center">
              <h2>Contato</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Faixa Negócio -->
    <div class="py-1" style="background: #25486b;"></div>

    <!-- Form -->
    <div class="pad-section" 
    :style="{ 'backgroundColor': contatoCor_bg}">
      <div class="container-xl">
        <div class="row justify-content-center">
          <div class="col-md-11 col-lg-10">
            <div class="folha p-3 p-lg-5">
              <form class="form-contato">
                <div class="row mb-4">
                  <div class="col-lg-4">
                    <div class="contato-item">
                      <h5 class="title-contato">Endereço</h5>
                      <p v-html="contatoEnd"></p>
                      
                      <div class="mb-4 mb-lg-0">
                        <a :href="contatoWaze" target="_blank">
                          <img class="img-fluid" src="img/18.png" alt="">
                        </a>
                        <a :href="contatoMaps" target="_blank">
                          <img class="img-fluid" src="img/19.png" alt="">
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <h5 class="title-contato">E-mail</h5>
                    <p v-html="contatoEmail"></p>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <h5 class="title-contato">Fone</h5>
                    <p v-html="contatoFone"></p>
                  </div>
                </div>
                <!-- Input Nome -->
                <div class="row">
                  <div class="col-md-4">
                    <input 
                      type="text" 
                      class="form-control input-contato mb-2" 
                      placeholder="Nome"
                      v-model="nome"
                    >
                  </div>
                  <!-- Input Email -->
                  <div class="col-md-4">
                    <input 
                      type="email" 
                      class="form-control input-contato mb-2" 
                      placeholder="E-mail" 
                      v-model="email"                      
                    >
                  </div>
                  <!-- Input Assunto -->
                  <div class="col-md-4">
                    <input 
                      type="text" 
                      class="form-control input-contato mb-2" 
                      placeholder="Assunto"
                      v-model="Contato.Assunto"
                    >
                  </div>
                  <!-- Texto do email (textarea) -->
                  <div class="col-12">
                    <textarea                      
                      class="form-control input-contato mb-2" 
                      id="#" 
                      placeholder="Escreva sua mensagem" 
                      rows="8"
                      v-model="texto"
                    >
                    </textarea>
                  </div>
                </div>

                <!-- Enviar -->
                <button 
                  type="button" 
                  class="btn btn-form mt-4"
                  @click="enviarDadosFormulario"
                >
                  Enviar Contato
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Galeria -->
    <div class="hidden-sm">
      <div class="container-fluid p-0">
        <div class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <!-- 1 -->
                <div 
                  v-for="imagem in imagens.slice(0, 7)" 
                  :key="imagem.institucionalImgId"
                  class="col-sm-2 p-1 insta_box"
                >
                  <img 
                    class="img-fluid" 
                    :src="$apiUrlIndex + imagem.urlImagem"
                  >                  
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script>

export default {
  
  data() {
    return {

      dadosContato: [],
      imagens: [],
      contatoCor_bg: '',
      contatoEmail: '',
      contatoFone: '',
      contatoWaze: '',
      contatoMaps: '',
      contatoEnd: '',

      // 
      nome: '',
      email: '',
      texto: '',

      // Objeto Contato      
      Contato: {
        NomeEmpresa: "Kemp Consultoria",
        Assunto: "",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: "",
        Anexo: "",
        NomeAnexo: "",
        TipoAnexo: "",
      }

    }
  },
  created() {
    //----------------- Dados Contato 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/dados%20contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.dadosContato = retornoAPI   
      this.contatoCor_bg = this.dadosContato[0].cor1       
      this.contatoEmail = this.dadosContato[0].referencia       
      this.contatoFone = this.dadosContato[0].campoExtra1       
      this.contatoWaze = this.dadosContato[0].campoExtra2       
      this.contatoMaps = this.dadosContato[0].campoExtra3       
      this.contatoEnd = this.dadosContato[0].resumo1   
      this.imagens =  this.dadosContato[0].institucionalImg  
    });

  },
  methods: {
    enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      this.Contato.Destinatario = this.contatoEmail
      //this.Contato.Destinatario = "joaokussler@gmail.com"

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = 
        "Nome:" + this.nome + 
        " <br /> Email: " + this.email +
        " <br /> Texto: " + this.texto;

      //validar vazios

      //SE NAO TIVER ANEXO:
      console.log(this.Contato)
      this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
      .then(response => {
        if(response.ok)
          alert("Enviado com sucesso!")
          this.nome=""
          this.email=""
          this.Contato.Assunto=""
          this.texto=""
      },error => {
        alert(error.bodyText)
          this.nome=""
          this.email=""
          this.Contato.Assunto=""
          this.texto=""
      });
      
    }
  }
}
</script>

<style scoped>

  @import '/css/galeria_1.css';

</style>