import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource'
import router from './router/routes'
import vSelect from 'vue-select'

// Bootstrap Vue
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

// Global Components
Vue.component('v-select', vSelect)

Vue.use(VueResource);

Vue.config.productionTip = false

//------------------- Chamada API
// Vue.prototype.$apiUrl = "http://www.sgcad21.kinghost.net/api";
// Vue.prototype.$apiUrl = "http://jusvirtua.com.br/api";
// Vue.prototype.$apiUrlIndex = "http://jusvirtua.com.br";
Vue.prototype.$apiUrl = "https://controle.kempconsultoria.com.br/api";
Vue.prototype.$apiUrlIndex = "https://controle.kempconsultoria.com.br/";

Vue.prototype.$redirect_reload = function(path) {
  this.$router.push(path);
  this.$router.go(this.$router.currentRoute);
};
Vue.prototype.$redirect = function(path) {
  this.$router.push(path);
};

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
