<template>
  
  <section class="sobre">

    <!-- Topo Título -->
    <div class="titulo-topo-internas pad-section bg-body-light">
      <div class="container-xl">
        <div class="row">
          <!--  data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" -->
          <div class="col text-center heading-section">
            <div class="text-center">
              <h2>Competências Centrais</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Faixa Negócio -->
    <div class="faixa-negocio py-1" style="background: #25486b;"></div>  

    <!-- Competências -->
    <div class="titulo-topo-internas bg-1 pad-section">
      <div class="container-xl">
        <div class="row">

          <div class="col-md-4 col-lg mb-5 mb-lg-5" v-for="comp in competencias.slice(0, 5)" :key="comp.id">
            <div v-if="comp.referencia == 'UP'" class="comp-item up set-img" 
            style="background-image: url(img/comp1.png)">
              <div class="comp-content-up">
                <h5 class="title-mod text-center mb-3" v-html="comp.nome"></h5>
                <p v-html="comp.resumo1"></p>
              </div>
            </div> 
            <div v-else class="comp-item down set-img" 
            style="background-image: url(img/comp2.png)">
              <div class="comp-content-donw">
                <h5 class="title-mod-black text-center mb-3" v-html="comp.nome"></h5>
                <p v-html="comp.resumo1"></p>
              </div>
            </div> 
          </div>

        </div>
      </div>
    </div>
      
    <!-- Modelo Operacional -->
    <div class="nossos-valores pad-section bg-2">
      <div class="container-xl">
        <div class="row mb-5">
          <div class="col text-center heading-section">
            <div class="text-center">
              <h4 class="title-secondary">Modelo Operacional Diferenciado</h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 mb-4" v-for="modelo in modelos" :key="modelo.id">
            <!-- Esq -->
            <div v-if="modelo.referencia == 'ESQ'"
            class="card-mod left set-img d-flex align-items-center" 
            style="background-image: url(img/08.png)">
              <div class="card-mod-content">
                <h5 
                  class="title-mod" 
                  :style="{ 'color': modelo.cor1}"
                  v-html="modelo.nome"
                ></h5>
                <p class="list-mod" v-html="modelo.resumo1"></p> 
              </div>
            </div>
            <!-- Dir -->
            <div v-else
            class="card-mod right set-img d-flex align-items-center" 
            style="background-image: url(img/09.png)">
              <div class="card-mod-content text-right-mobile">
                <div>
                  <h5 
                    class="title-mod" 
                    :style="{ 'color': modelo.cor1}"
                    v-html="modelo.nome"></h5>
                  <p class="list-mod" v-html="modelo.resumo1"></p>
                </div>               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script>

export default {
  
  data() {
    return {
      // Competências
      competencias: [],
      
      // MOD
      modelos: [],
    }
  },
  created() {
    //----------------- Competências (Centrais)
    this.$http
    .get(this.$apiUrl + "/institucional/telas/centrais")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.competencias = retornoAPI.filter(x => x.visivel)      
      this.competencias.sort(function (a, b) {
        if (a.ordem > b.ordem) {return 1;}        
        if (a.ordem < b.ordem) {return -1;}       
        return 0;
      })                         
    });
    //----------------- MOD 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/modelo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.modelos = retornoAPI.filter(x => x.visivel)      
      this.modelos.sort(function (a, b) {
        if (a.ordem > b.ordem) {return 1;}        
        if (a.ordem < b.ordem) {return -1;}       
        return 0;
      })                
    });

  }
}
</script>