<template>
  
  <section>

    <!-- Topo Título -->
    <div class="titulo-topo-internas pad-section bg-body-light">
      <div class="container-xl">
        <div class="row">
          <!--  data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" -->
          <div class="col text-center heading-section">
            <div class="text-center">
              <h2>Política de Privacidade</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Faixa Negócio -->
    <div class="faixa-negocio py-1" style="background: #25486b;"></div>

    <!-- Descrição Serviços -->
    <div class="desc-servicos py-5">
      <div class="container-xl">
        
        <div class="row mt-5">
          <div class="col">
            <div class="text-center">
              <h4 class="title-secondary mb-4" v-html="privacidadeTitulo"></h4>
            </div>
              <p v-html="privacidadeTexto"></p>
          </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script>

export default {
  
  data() {
    return {
      privacidade: [],
      privacidadeTitulo: '',
      privacidadeTexto: ''
    }
  },
  created() {
    //-----------------  Nossos Serviços (Home)
    this.$http
    .get(this.$apiUrl + "/institucional/telas/politica%20de%20privacidade")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.privacidade = retornoAPI
      this.privacidadeTitulo = this.privacidade[0].nome
      this.privacidadeTexto = this.privacidade[0].resumo1
    });
  }
}
</script>