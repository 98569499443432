import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Home from '@/components/site/Home.vue'
import Sobre from '@/components/site/Sobre.vue'
import Competencias from '@/components/site/Competencias.vue'
import Story from '@/components/site/Story.vue'
import Servicos from '@/components/site/Servicos.vue'
import Curriculos from '@/components/site/Curriculos.vue'
import Contato from '@/components/site/Contato.vue'
import Depoimentos from '@/components/site/Depoimentos.vue'
import Privacidade from '@/components/site/Privacidade.vue'
import Cookies from '@/components/site/Cookies.vue'

export default new VueRouter({
  mode: 'history',
  linkActiveClass: 'router-link-active',
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if(savedPosition) {
          return resolve(savedPosition)
        }
        if(to.hash) {
          return resolve({
            selector: to.hash,
            offset: {x: 0, y: 0}
          })
        }
        return resolve({ 
          x: 0, y: 0,
          behavior: 'smooth'
        })
      }, 700) 
    })
  },
  routes: [
    {path: '/', component: Home},
    {path: '/sobre', component: Sobre},
    {path: '/competencias', component: Competencias},
    {path: '/story', component: Story},
    {path: '/servicos', component: Servicos},
    {path: '/servicos/:urlServico/:idServico', component: Servicos},
    {path: '/curriculos', component: Curriculos},
    {path: '/contato', component: Contato},
    {path: '/depoimentos', component: Depoimentos},
    {path: '/depoimentos/:idInfo', component: Depoimentos, name: 'depoimento'},
    {path: '/privacidade', component: Privacidade},
    {path: '/cookies', component: Cookies},
   
  ]
})