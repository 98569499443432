<template>
  
  <section>

    <!-- Topo Título -->
    <div class="titulo-topo-internas pad-section bg-body-light">
      <div class="container-xl">
        <div class="row">
          <!--  data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" -->
          <div class="col text-center heading-section">
            <div class="text-center">
              <h2>{{ chamadaTitulo }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Faixa Negócio -->
    <div class="faixa-negocio py-4" style="background: #25486b;">   
      <div class="container-xl">
        <div class="row">
          <div class="col text-center heading-section">
            <div class="text-center text-white">
              <p v-html="chamadaTexto"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Marca + Palavra Fundador -->
    <div class="pad-section">   
      <div class="container-xl">
        <div class="row justify-content-center">
          <div class="col-lg-10 heading-section">
            <div class="row">
              <!-- Somente Mobile -->
              <div class="col mb-5 hidden-lg hidden-xl">
                <div class="row">
                  <div class="col-12 text-center">
                    <div class="marca text-center mb-4">
                      <img class="img-fluid" src="img/10.png" alt="">
                    </div>
                  </div>
                  <!-- <div class="col-12 col-md-8 offset-md-2 text-center">
                    <v-select 
                      v-model="servicoSelected"   
                      @input="MudaServico"
                      :options="servicos"
                      :value="nome => nome.institucionalId"
                      single-line
                      label="nome" 
                      class="">
                    </v-select>
                  </div> -->
                </div>
              </div>
              <!--  -->
              <!-- Somente Desktop -->
              <div class=" col hidden-sm-md hidden-lg">
                <div class="marca text-center mb-4">
                  <img class="img-fluid" src="img/10.png" alt="">
                </div>
                <div class="marca text-center mb-4">
                  <img class="riscado img-fluid" src="img/10a.png" alt="">
                </div>
              </div>
              <!--  -->
            </div>
            <div class="row mb-5 hidden-sm-md hidden-lg">
              <div class="col-lg"
              v-for="servico in servicos.slice(0, 5)" :key="servico.id">
                <div class="topicos-marca text-center">
                  <!-- <router-link :to="/servicos/ + servico.institucionalId">
                    <h6 class="title-topicos">{{ servico.nome }}</h6>
                  </router-link> -->
                  <a 
                    href="#" 
                    @click.prevent="$redirect_reload('/servicos/' + servico.nome.replaceAll(' ','-').replaceAll('/','') + '/' + servico.institucionalId)"
                  >
                    <h6 class="title-topicos">{{ servico.nome }}</h6>
                  </a>
                </div>
              </div>
            </div>
            <!-- Card Fundador-->
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <div class="card-fundador p-2">
                  <div class="row">
                    <div class="col text-center pt-4">
                      <h4 class="title-secondary">{{ citacaoTitulo }}</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="card-fundador-content p-lg-3">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="card-fundador-perfil text-center">
                              <img :src="citacaoIMG" alt="">
                              <h5 class="title-perfil mt-4">{{ citacaoNome }}</h5>
                              <span class="span-perfil">{{ citacaoCargo }}</span>
                            </div>
                          </div>
                          <div class="col-md-8 mt-4 mt-lg-0">
                            <div class="card-fundador-citacao p-3">
                              <p class="citacao" v-html="citacaoTexto"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Atuação -->
    <div class="atuacao pad-section bg-1">   
      <div class="container-xl">
        <div class="row">
          <div class="col text-center mb-5 mb-lg-4">
            <h4 class="title-secondary">{{ atuacaoImagensTitulo }}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-8 flex-center">
            <div class="atuacao-img">
              <img class="img-fluid" :src="atuacaoImagensIMG">              
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mt-4 mt-md-0">
            <div v-for="atuacao in atuacoes.slice(0, 6)" :key="atuacao.id"
            class="atuacao-item mb-3"
            :style="{ 'backgroundColor': atuacao.cor1}">
            
              <div class="text-center">
                <h6 class="title-atuacao" v-html="atuacao.nome"></h6>
                <p class="subTitle-atuacao" v-html="atuacao.resumo1"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script>

export default {
  
  data() {
    return {
      // Chamada
      chamada: [],

      // Serviços
      servicos: [],
      servicoSelected: null,
      chamadaTitulo: '',
      chamadaTexto: '',
      
      // Palavra
      citacao: [],
      citacaoIMG: '',
      citacaoNome: '',
      citacaoCargo: '',
      citacaoTitulo: '',
      citacaoTexto: '',

      // Atuação
      atuacoes: [],
      atuacaoImagens: [],
      atuacaoImagensIMG: '',
      atuacaoImagensTitulo: '',

    }
  },
  created() {
    //-----------------  Chamada
    this.$http
    .get(this.$apiUrl + "/institucional/telas/chamada")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.chamada = retornoAPI
      this.chamadaTitulo = this.chamada[0].nome
      this.chamadaTexto = this.chamada[0].resumo1
    });
    //-----------------  Serviços
    this.$http
    .get(this.$apiUrl + "/institucional/telas/serviços")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.servicos = retornoAPI.filter(x => x.visivel)      
      this.servicos.sort(function (a, b) {
        if (a.ordem > b.ordem) {return 1;}        
        if (a.ordem < b.ordem) {return -1;}       
        return 0;
      }).reverse()
    });
    //-----------------  Palavra Fundador
    this.$http
    .get(this.$apiUrl + "/institucional/telas/palavra")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.citacao = retornoAPI  
      this.citacaoIMG = this.$apiUrlIndex + this.citacao[0].institucionalImg[0].urlImagem 
      this.citacaoNome = this.citacao[0].nome    
      this.citacaoCargo = this.citacao[0].referencia    
      this.citacaoTitulo = this.citacao[0].campoExtra1    
      this.citacaoTexto = this.citacao[0].resumo1    
    });
    //----------------- Atuação Itens 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/atuação%20itens")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.atuacoes = retornoAPI.filter(x => x.visivel) 
      this.atuacoes.sort(function (a, b) {
        if (a.ordem > b.ordem) {return 1;}        
        if (a.ordem < b.ordem) {return -1;}       
        return 0;
      }).reverse()     
    });
    
    //----------------- Atuação Imagens 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/atuaçao%20imagem")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.atuacaoImagens = retornoAPI 
      this.atuacaoImagensIMG = this.$apiUrlIndex + this.atuacaoImagens[0].institucionalImg[0].urlImagem 
      this.atuacaoImagensTitulo = this.atuacaoImagens[0].nome   
    });

  },
  methods: {
    MudaServico(value) {
      console.log(value)
      this.$redirect_reload("/servicos/" + value.institucionalId);
    }
  }
}
</script>
