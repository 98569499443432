<template>
  
  <section>

    <!-- Topo Título -->
    <div class="titulo-topo-internas pad-section bg-body-light">
      <div class="container-xl">
        <div class="row">
          <!--  data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" -->
          <div class="col text-center heading-section">
            <div class="text-center">
              <h2 v-html="negocioTitulo1"></h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Faixa Negócio -->
    <div class="faixa-negocio py-4" style="background: #25486b;">   
      <div class="container-xl">
        <div class="row">
          <div class="col text-center heading-section">
            <div class="text-center text-white">
              <h3 class="text-white" v-html="negocioTitulo2"></h3>
              <p v-html="negocioResumo"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MVV -->
    <div class="mvv pad-section bg-1">   
      <div class="container-xl">
        <div class="row mb-4">
          <div class="col text-center heading-section">
            <div class="text-center text-white">
              <h4 class="title-secondary" v-html="negocioTitulo3"></h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5 d-flex align-items-center mb-5 mb-lg-0">
            <div class="circle m-auto" 
            :style="{ backgroundImage: 'url(' + negocioIMG + ')' }">          
            </div>
          </div>
          <div class="col-lg-7">
            <div class="mvv-item" :style="{ 'backgroundColor': negocioVisao_BG}">
              <p v-html="negocioVisao"></p>
            </div>
            <div class="mvv-item" :style="{ 'backgroundColor': negocioMissao_BG}">
              <p v-html="negocioMissao"></p>
            </div>
            <div class="mvv-item" :style="{ 'backgroundColor': negocioValores_BG}">
              <p v-html="negocioValores"></p>
            </div>            
          </div>
        </div>
      </div>
    </div>

    <!-- Nossos Valores -->
    <div class="nossos-valores pad-section">
      <div class="container-xl">
        <div class="row mb-5">
          <div class="col text-center heading-section">
            <div class="text-center">
              <h4 class="title-secondary">Nossos Valores</h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-5" v-for="valor in valores" :key="valor.id">
            <div class="card-valores">
              <div class="card-valores-header">
                <h3 class="card-valores-title" v-html="valor.nome"></h3>
                <!-- {{ valores[0].nome }} -->
              </div>
              <div class="card-valores-content">
                <p v-html="valor.resumo1"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Histórico (Timeline) -->
    <div class="historico pad-section bg-white">
      <div class="container-xl">
        <div class="row mb-5">
          <div class="col text-center heading-section">
            <div class="text-center">
              <h4 class="title-secondary">Histórico</h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7 offset-md-3 col-lg-6 offset-lg-5">
            <ul class="timeline">
              <li v-for="historia in historico" :key="historia.id">
                <h2 class="time-data" v-html="historia.nome"></h2>
                <p class="time-text" v-html="historia.resumo1"></p>
              </li>              
            </ul>
          </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script>

export default {
  
  data() {
    return {
      // Negócio
      negocio: [],
      negocioIMG: '',
      negocioTitulo1: '',
      negocioTitulo2: '',
      negocioTitulo3: '',
      negocioResumo: '',
      negocioMissao: '',
      negocioMissao_BG: '',
      negocioVisao: '',
      negocioVisao_BG: '',
      negocioValores: '',
      negocioValores_BG: '',

      // Valores
      valores: [],

      // Histórico
      historico: []
    }
  },
  created() {
    //----------------- Negócio 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/negocio")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.negocio = retornoAPI  
      this.negocioIMG = this.$apiUrlIndex + this.negocio[0].institucionalImg[0].urlImagem
      this.negocioTitulo1 = this.negocio[0].nome        
      this.negocioTitulo2 = this.negocio[0].referencia        
      this.negocioTitulo3 = this.negocio[0].campoExtra1        
      this.negocioResumo = this.negocio[0].resumo1        
      this.negocioMissao = this.negocio[0].resumo2        
      this.negocioVisao = this.negocio[0].descricao1        
      this.negocioValores = this.negocio[0].descricao2        
      this.negocioMissao_BG = this.negocio[0].cor1        
      this.negocioVisao_BG = this.negocio[0].cor2        
      this.negocioValores_BG = this.negocio[0].cor3        
    });
    //----------------- Valores 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/valores")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.valores = retornoAPI.filter(x => x.visivel)      
      this.valores.sort(function (a, b) {
        if (a.ordem > b.ordem) {return 1;}        
        if (a.ordem < b.ordem) {return -1;}       
        return 0;
      }).reverse()        
    });
    //----------------- Histórico 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/historico")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.historico = retornoAPI.filter(x => x.visivel)      
      this.historico.sort(function (a, b) {
        if (a.ordem > b.ordem) {return 1;}        
        if (a.ordem < b.ordem) {return -1;}       
        return 0;
      })
    });

  }
}
</script>

<style scoped>

  @import '/css/timeline.css';

</style>