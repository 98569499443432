<template>
  
  <section>

    <!-- Topo Título -->
    <div class="titulo-topo-internas pad-section bg-body-light">
      <div class="container-xl">
        <div class="row">
          <!--  data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" -->
          <div class="col text-center heading-section">
            <div class="text-center">
              <h2>Depoimentos</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Faixa Negócio -->
    <div class="py-1" style="background: #25486b;"></div>

    <!-- Principal -->
    <div class="depoimentos pad-section">
      <div class="container-xl">
        <div class="row">
          <!-- Main -->
          <div class="col-lg-8">
            <div 
            class="depo-main heading-section">
              <img class="img-fluid mb-4" 
              :src="infoIMG">
              <h3>{{ infoTitulo }}</h3>
              <p>{{ infoTexto }}</p>
            </div>
          </div>
          <!-- Side -->
          <div class="col-lg-4">
            <div class="side-depo">
              <h4 class="title-secondary mb-4">Depoimentos Recentes</h4>
              <div 
              v-for="depoimento in depoimentos" :key="depoimento.id"
              class="depo-item mb-4">              
                <div class="depo-item-img">
                  <img class="img-fluid" 
                  :src="$apiUrlIndex + depoimento.institucionalImg[0].urlImagem">
                </div>
                <div class="depo-item-title">
                  <a href="#" @click="carregaInfo(depoimento.institucionalId)">
                    <h6 class="depo-item-link">{{ depoimento.nome }}</h6>
                  </a>
                  <span class="depo-item-data">
                    <span class="fa fa-calendar icon-calendar"></span>
                    {{ currentDateTime(depoimento.data1) }}
                  </span>
                </div>
              </div>
            </div>        
          </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script>
import moment from 'moment'

export default {

  data() {
    return {
      depoimentos: [],
      depoimento: {},
      infoIMG: '',
      infoTitulo: '',
      infoTexto: '',
    }
  },
  created() {
    //----------------- Depoimentos 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/depoimentos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.depoimentos = retornoAPI.filter(x => x.visivel)      
      this.depoimentos.sort(function (a, b) {
        if (a.ordem > b.ordem) {return 1;}        
        if (a.ordem < b.ordem) {return -1;}       
        return 0;
      }).reverse()   
      
      // Default: Ao carregar o site, trazer depoimento mais recente
      this.infoTitulo = this.depoimentos.slice(0, 1)[0].nome;
      this.infoTexto = this.depoimentos.slice(0, 1)[0].resumo1; 
      if (this.depoimentos.slice(0, 1)[0].institucionalImg[0] != null) {    
        this.infoIMG = this.$apiUrlIndex + this.depoimentos.slice(0, 1)[0].institucionalImg[0].urlImagem;
      }
      else {
        this.infoIMG = '';
      }

    });
  },
  methods: {
    carregaInfo(idInfo) {      
      this.$http
      .get(this.$apiUrl + "/institucional/" + idInfo)
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.depoimento = retornoAPI 

        this.infoTitulo = this.depoimento.nome;
        this.infoTexto = this.depoimento.resumo1; 
        if (this.depoimento.institucionalImg[0] != null) {          
            this.infoIMG = this.$apiUrlIndex + this.depoimento.institucionalImg[0].urlImagem;
          }
          else {          
            this.infoIMG = '';
          }
        
      });
    },
    currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    },
    
  }
}
</script>