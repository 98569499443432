<template>

  <footer class="ftco-footer img" style="background-color: #f4f8ff;">
    <!-- <div class="overlay"></div> -->
    <div class="container-xl">
      <div class="row mb-5 justify-content-between">
        <!-- Logo -->
        <div class="col-lg-12 mb-5">
          <div class="ftco-footer-widget mb-4">
            <router-link to="/" class="navbar-brand">
              <img src="/img/05.png">
            </router-link>
          </div>
        </div>
        <!-- Fale conosco (Sociais) -->
        <div class="col-md-4 col-lg-4 mr-xl-5">
          <div class="ftco-footer-widget mb-4">
            <h2 class="ftco-heading-2">Fale Conosco</h2>
            <div class="footer-endereco">
              <p v-html="textoFooter"></p>
              <!-- Sociais -->
              <ul class="ftco-footer-social mt-4">
                <li>
                  <a class="color-facebook" :href="facebookLink" target="_blank">
                    <span class="fa fa-facebook"></span>
                  </a>
                </li>
                <li>
                  <a class="color-linkedin" :href="linkedinLink" target="_blank">
                    <span class="fa fa-linkedin"></span>
                  </a>
                </li>
                <li>
                  <a class="color-instagram" :href="instagramLink" target="_blank">
                    <span class="fa fa-instagram"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Menu -->
        <div class="col-md-4 col-lg">
          <div class="ftco-footer-widget mb-4">
            <h2 class="ftco-heading-2">Menu</h2>
            <div class="d-md-flex">
              <ul class="list-unstyled w-100">
                <li><router-link to="/sobre">Sobre a Kemp</router-link></li>                 
                <li><router-link to="/sobre">Estrutura Organizacional</router-link></li>
                <li><router-link to="/curriculos">Currículos</router-link></li>
                <li><router-link to="/contato">Contato</router-link></li>
                <li><router-link to="/depoimentos">Depoimentos</router-link></li>
                <li><router-link to="/privacidade">Política de Privacidade</router-link></li>
                <li><router-link to="/cookies">Política de Cookies</router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg">
          <div class="ftco-footer-widget mb-4">
            <h2 class="ftco-heading-2">Serviços</h2>
            <div class="d-md-flex">
              <ul class="list-unstyled w-100">
                <li v-for="servico in servicos" :key="servico.id"> 
                  <!-- <router-link :to="/servicos/ + servico.institucionalId">
                    <span class="ion ion-ios-arrow-round-forward me-2"></span>
                    {{ servico.nome }}
                  </router-link> -->
                  <a 
                    href="#" 
                    @click.prevent="$redirect_reload('/servicos/' + servico.nome.replaceAll(' ','-').replaceAll('/','') + '/' + servico.institucionalId)"
                  >
                    <span class="ion ion-ios-arrow-round-forward me-2"></span>
                    {{ servico.nome }}
                  </a>
                  
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="container-fluid px-0 py-5">
      <div class="container-xl">
        <div class="row">
          <div class="col-md-12 text-center">
            <a href="https://avancedigital.com.br/" target="_blank">
              <img src="/img/ad.png">
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>
export default {
  data() {
    return {
      servicos: [],
      informacoes: [],
      textoFooter: '',

      // Sociais
      facebookLink: '',
      linkedinLink: '',
      instagramLink: '',
    }
  },
  created() {
    //-----------------  Serviços
    this.$http
    .get(this.$apiUrl + "/institucional/telas/serviços")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.servicos = retornoAPI.filter(x => x.visivel)      
      this.servicos.sort(function (a, b) {
        if (a.ordem > b.ordem) {return 1;}        
        if (a.ordem < b.ordem) {return -1;}       
        return 0;
      }).reverse()
    });
    //----------------- Informações 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/informações")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.informacoes = retornoAPI     
      this.textoFooter =  this.informacoes[0].resumo1
    });
    //----------------- Facebook 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/facebook")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.facebook = retornoAPI     
      this.facebookLink =  this.facebook[0].nome
    });
    //----------------- Linkedin 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/linkedin")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.linkedin = retornoAPI     
      this.linkedinLink =  this.linkedin[0].nome
    });
    //----------------- Facebook 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/instagram")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.instagram = retornoAPI     
      this.instagramLink =  this.instagram[0].nome
    });
  }
}
</script>