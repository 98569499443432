<template>
  <div id="app">
    <div v-html="codigo_SEO"></div>
    <Header />
    
    <transition name="fadeIn">
      <router-view></router-view>
    </transition>

    <CTA />
    <Footer />
  </div>
</template>

<script>
// import * as bsmin from '../public/js/main.js'


import Header from '@/components/site/shared/Header.vue'
import CTA from '@/components/site/shared/CTA.vue'
import Footer from '@/components/site/shared/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    CTA,
    Footer
  },
  data() {
    return {
      // SEO
      seobody: [],
      codigo_SEO: '',

      // Head
      seoHead:[],
      codigoHead: '',

      // Analitics
      seoAnalitics:[],
      codigoAnalitics: '',

      // Home
      metaHome: [],
      codigoHome: ''
      
    }
  },
  created() {
    //----------------- seobody
    /*$route.fullPath*/
    this.$http
		.get(this.$apiUrl + "/seobody/")
		.then((res) => res.json())
		.then((retornoAPI) => {
			this.seobody = retornoAPI
      this.codigo_SEO = this.seobody[0].codigo
    });

    //----------------- seohead
    this.$http
    .get(this.$apiUrl + "/seohead/")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.seoHead = retornoAPI;
      this.codigoHead = this.seoHead[0].codigo;
    });

    //----------------- analitycs
    this.$http
    .get(this.$apiUrl + "/seoanalitycs/")
    .then((res) => res.json())
    .then((idi) => {
        this.seoAnalitics = idi;
        this.codigoAnalitics = this.seoAnalitics[0].codigo;
      });

      //chama o get da api this.$apiUrl
      this.$http
      .get(this.$apiUrl + "/Seometahome/")
      .then((res) => res.json())
      .then((idi) => {
        this.metaHome = idi;
        this.codigoHome = this.metaHome[0].codigo;
        document.head.innerHTML += this.codigoHead.trim('"') + this.codigoAnalitics.trim('"') + this.codigoHome.trim('"');
      });
  }
}
</script>

<style>

  @import '../public/css/bootstrap/vendor/_rfs.css';
  @import '../public/css/bootstrap/bootstrap-grid.css';
  @import '../public/css/bootstrap/bootstrap-reboot.css';
  @import '../public/css/bootstrap/bootstrap-utilities.css';
  
  @import '../public/css/animate.css';
  @import '../public/css/aos.css';
  @import '../public/css/datepicker.min.css';
  @import '../public/css/flaticon.css';
  @import '../public/css/glightbox.min.css';
  @import '../public/css/style.css';
  @import '../public/css/tiny-slider.css';
  @import '../public/css/kemp.css';

  /*---------- Animations */
  .fadeIn-enter {
    opacity: 0;
  }
  .fadeIn-enter-active {
    transition: opacity .8s;
  }
  .fadeIn-enter-to {
  opacity: 1;
  }

</style>
