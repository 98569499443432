<template>
  
  <div class="home">

    <div class="hidden-sm">
      <b-carousel
        id="carousel-1"
        :interval="7000"
        controls
        indicators
        img-width="1024"
        img-height="480"
      >
        <b-carousel-slide
          class="slide-item set-img"
          v-for="slide in slider" :key="slide.id"
          :img-src="$apiUrlIndex + slide.institucionalImg[0].urlImagem"                   
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-9 offset-xl-3">
                <div class="slider-content">
                  <h2 
                    class="title-slider text-white" 
                    v-html="slide.resumo1"
                  >
                  </h2>
                  <p v-html="slide.descricao1"></p>
                </div>
              </div>
            </div>
          </div>
        
        </b-carousel-slide>

      </b-carousel>
    </div>
    
    <!-- Video -->
    <section class="ftco-section section-video bg-white" v-if="temVideo">
      <div class="container-xl">
        <div class="row justify-content-center">
          <!--  data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" -->
          <div class="col-lg-10 text-center heading-section">
            <div class="text-center">
              <h2 class="mb-4" v-html="tituloVideo"></h2>
            </div>
            <div class="frame-video">
              <iframe width="100%" height="500" :src="linkVideo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Nossos serviços -->
    <section class="ftco-section bg-body-light">
      <div class="container-xl">
        <div class="row g-lg-5">
          <!--  data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" -->
          <div class="col-md-11 col-xl-9 heading-section">
            <div class="">
              <span class="subheading">{{ preTituloServicos }}</span>
              <h2 class="mb-4" v-html="tituloServicos"></h2>
              <p v-html="descricaoServicos"></p>
            </div>
          </div>
          <!--  data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" -->
          <div class="col-lg-12 d-flex align-items-stretch mt-5 mt-lg-0">
            <div class="row">
              <div class="col-md-6 col-lg mb-4 mb-lg-0"   
              v-for="card in cardServicos.slice(0, 5)" :key="card.id">   
                <div class="card-services-home">
                  <div class="icon mb-3">
                    <!-- <span class="flaticon-accountant"></span> -->
                    <img :src="$apiUrlIndex + card.institucionalImg[0].urlImagem" alt="">
                  </div>
                  <div class="card-services-text">
                    <h2>{{ card.nome }}</h2>
                    <p class="mb-0" v-html="card.resumo1"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Sobre a Empresa -->
    <section class="ftco-section ftco-image img" :style="{ backgroundImage: 'url(' + bg_sobreEmpresa + ')' }">
      <div class="container">
        <div class="row justify-content-start">
          <!--  data-aos="fade-up" data-aos-duration="1000" -->
          <div class="col-md-8 heading-section">
            <span class="subheading">{{ preTituloSobreEmpresa }}</span>
            <h2 class="mb-4" v-html="tituloSobreEmpresa"></h2>
            <p v-html="descricaoSobreEmpresa"></p>
          </div>
        </div>
      </div>
    </section>

  </div>

</template>

<script>
import $ from 'jquery'

export default {
  components: {
  },
  data() {
    return {
      temVideo: false,
      slider: [],

      // Vídeo
      video: [],
      tituloVideo: '',
      linkVideo: '',

      // Nossos Serviços
      servicos2: [],
      preTituloServicos: '',
      tituloServicos:'',
      descricaoServicos: '',
      // Cards
      cardServicos: [],


      // Sobre a Empresa
      sobreEmpresa: [],
      bg_sobreEmpresa: '',
      preTituloSobreEmpresa: '',
      tituloSobreEmpresa: '',
      descricaoSobreEmpresa: ''

    }
  },
  methods:{
    recarrega(){
      this.$redirect_reload('/');
    }
  },
  created() {
    setInterval(() =>{
      var lang = $(".goog-te-menu-value span:first").text();
      if(lang != null && lang.length > 0 && lang.toLowerCase().includes('port')){
        this.recarrega();
      }
    },1000);
    //----------------- Carousel 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/slide")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.slider = retornoAPI.filter(x => x.visivel)      
      this.slider.sort(function (a, b) {
        if (a.ordem > b.ordem) {
          return 1;
        }
        if (a.ordem < b.ordem) {
          return -1;
        }
        return 0;
      }).reverse()       
    });
    //----------------- Video 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/video")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.video = retornoAPI  
      this.tituloVideo = this.video[0].nome          
      this.linkVideo = this.video[0].referencia
      if(this.linkVideo != null && this.linkVideo.length > 0){
        this.temVideo = true;
      }
    });
    //----------------- Nossos Serviços 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/nossos%20serviços")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.servicos2 = retornoAPI  
      this.preTituloServicos = this.servicos2[0].nome    
      this.tituloServicos = this.servicos2[0].resumo1    
      this.descricaoServicos = this.servicos2[0].descricao1       
    });
    //----------------- Cards (Nossos Serviços)
    this.$http
    .get(this.$apiUrl + "/institucional/telas/serviços%20home")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.cardServicos = retornoAPI.filter(x => x.visivel)      
      this.cardServicos.sort(function (a, b) {
        if (a.ordem > b.ordem) {
          return 1;
        }
        if (a.ordem < b.ordem) {
          return -1;
        }
        return 0;
      }).reverse()
    });
    //----------------- Sobre a Empresa 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/sobre%20kemp")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.sobreEmpresa = retornoAPI  
      this.bg_sobreEmpresa = this.$apiUrlIndex + this.sobreEmpresa[0].institucionalImg[0].urlImagem 
      this.preTituloSobreEmpresa = this.sobreEmpresa[0].nome    
      this.tituloSobreEmpresa = this.sobreEmpresa[0].resumo1    
      this.descricaoSobreEmpresa = this.sobreEmpresa[0].descricao1       
    });
  }
}
</script>

<style scoped>
  
  @import '/css/slider.css';

  .carousel-caption {
    left: 5% !important;
    right: 5% !important;
  }

  .carousel-indicators li {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
  


</style>