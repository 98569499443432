<template>
  
  <section>

    <!-- Topo Título -->
    <div class="titulo-topo-internas pad-section bg-body-light">
      <div class="container-xl">
        <div class="row">
          <!--  data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" -->
          <div class="col text-center heading-section">
            <div class="text-center">
              <h2>{{ servicoTitulo }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Faixa Negócio -->
    <div class="faixa-negocio py-1" style="background: #25486b;"></div>

    <!-- Menu Serviços -->
    <div class="titulo-topo-internas py-2">
      <div class="container-xl">
        <h4 class="hidden-lg hidden-xl mb-4">Nossos Serviços</h4>
        <div class="row">
          <div 
            class="menu-servicos-mobile col-lg"
            v-for="servico in servicos.slice(0, 5)" :key="servico.id"
          >          
            <div class="text-lg-center">
              <!-- <a 
                href="#" 
                class="link-servicos" 
                @click.prevent="carregaServico(servico.institucionalId)"
              >
                <i class="fas fa-angle-double-right arrow mr-10 hidden-lg hidden-xl"></i>
                {{ servico.nome }}
              </a> -->
              
              <a 
                href="#" 
                class="link-servicos"
                @click.prevent="$redirect_reload('/servicos/' + servico.nome.replaceAll(' ','-').replaceAll('/','') + '/' + servico.institucionalId)" 
              >
                {{ servico.nome }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Descrição Serviços -->
    <div class="desc-servicos py-5">
      <div class="container-xl">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 text-center">
            <div class="desc-servicos-img">
              <img class="img-fluid" :src="servicoIMG">
            </div>
          </div>
          <div class="col-lg-6 d-flex align-items-center">
            <div class="desc-servicos-content">
              <h4 class="title-secondary text-center-mobile mt-5 mt-lg-0 mb-4" v-html="servicoTituloTexto"></h4>
              <p v-html="servicoTexto"></p>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12">
            <div class="text-center">
              <h4 class="title-secondary mb-4" v-html="servicoTituloDetalhe"></h4>
            </div>

            <!-- <div 
              class="text-center mt-4"
              v-if="servicoId == 718"
            >
              <img class="img-fluid" src="/img/kemp.png">
            </div> -->


            <!-- Pelo Id não traz a imagem, quando clica em serviços(menu superior), ou então, aplicar redirect no link (idem footer) -->
            <div 
              class="text-center mt-4"
              v-if="servicoTitulo == 'Seleção de Executivos'"
            >
              <img class="img-fluid" src="/img/kemp.png">
            </div>
            <p 
              v-html="servicoTextoDetalhe"
              v-else
            >
            </p>
          </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script>

export default {
  
  data() {
    return {
      servicos: [],
      servico: [],
      servicoId: '',
      servicoTitulo: '',
      servicoTituloTexto: '',
      servicoTexto: '',
      servicoTituloDetalhe: '',
      servicoTextoDetalhe: '',
      servicoIMG: '',

    }
  },
  created() {
    //-----------------  Serviços
    this.$http
    .get(this.$apiUrl + "/institucional/telas/serviços")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.servicos = retornoAPI.filter(x => x.visivel)      
      this.servicos.sort(function (a, b) {
        if (a.ordem > b.ordem) {return 1;}        
        if (a.ordem < b.ordem) {return -1;}       
        return 0;
      }).reverse()
      
      if (typeof this.$route.params.idServico != 'undefined') {          
        this.carregaServico(this.$route.params.idServico);
      }
      else {
        this.servicoTitulo = this.servicos[0].nome
        this.servicoTituloTexto = this.servicos[0].resumo1 
        this.servicoTexto = this.servicos[0].descricao1 
        this.servicoTituloDetalhe = this.servicos[0].descricao2 
        this.servicoTextoDetalhe = this.servicos[0].descricao3 
        if (this.servicos[0].institucionalImg[0] != null) {          
          this.servicoIMG = this.$apiUrlIndex + this.servicos[0].institucionalImg[0].urlImagem
        }
        else {          
          this.servicoIMG = '';
        }
      }
      
    });
  },
  methods: {
    carregaServico(idServico) {      
      this.$http
      .get(this.$apiUrl + "/institucional/" + idServico)
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.servico = retornoAPI 
        console.log(this.servico.institucionalId)
        this.servicoId = this.servico.institucionalId
        this.servicoTitulo = this.servico.nome
        this.servicoTituloTexto = this.servico.resumo1 
        this.servicoTexto = this.servico.descricao1 
        this.servicoTituloDetalhe = this.servico.descricao2 
        this.servicoTextoDetalhe = this.servico.descricao3 
        if (this.servico.institucionalImg[0] != null) {          
          this.servicoIMG = this.$apiUrlIndex + this.servico.institucionalImg[0].urlImagem
        }
        else {          
          this.servicoIMG = '';
        }
        
      });
    }
    
  }
}
</script>