<template>
  
  <div>
    <b-navbar class="navbar-mobile navbar-desktop px-2 px-xl-4" toggleable="lg">
      <b-navbar-brand>
        <router-link to="/" class="navbar-brand">
          <img class="logo-lg hidden-sm-md hidden-lg" src="/img/01.png">
          <img class="logo-header-mobile hidden-xl" src="/img/10.png">
        </router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse">
        <!-- <img src="/img/hamb.png" alt=""> -->
        <i class="fas fa-bars hamburguer text-white"></i>
      </b-navbar-toggle>

      <b-collapse class="nav-desktop-center" id="nav-collapse" is-nav>
        <div class="social-mobile hidden-lg hidden-xl">
          <ul class="ftco-footer-social list-social-mobile text-center">
            <li>
              <a class="color-facebook" :href="facebookLink" target="_blank">
                <span class="fa fa-facebook"></span>
              </a>
            </li>
            <li>
              <a class="color-linkedin" :href="linkedinLink" target="_blank">
                <span class="fa fa-linkedin"></span>
              </a>
            </li>
            <li>
              <a class="color-instagram" :href="instagramLink" target="_blank">
                <span class="fa fa-instagram"></span>
              </a>
            </li>
          </ul>
        </div>
        <b-navbar-nav>
          <b-nav-item>
            <router-link to="/" class="nav-link nav-link-mobile">
              <i class="fas fa-angle-double-right arrow mr-10 hide-icon"></i>Home
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/sobre" class="nav-link nav-link-mobile">
              <i class="fas fa-angle-double-right arrow mr-10 hide-icon"></i>Sobre
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/competencias" class="nav-link nav-link-mobile">
              <i class="fas fa-angle-double-right arrow mr-10 hide-icon"></i>Competências
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/story" class="nav-link nav-link-mobile">
              <i class="fas fa-angle-double-right arrow mr-10 hide-icon"></i>Story Telling
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/servicos" class="nav-link nav-link-mobile">
              <i class="fas fa-angle-double-right arrow mr-10 hide-icon"></i>Serviços
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/curriculos" class="nav-link nav-link-mobile">
              <i class="fas fa-angle-double-right arrow mr-10 hide-icon"></i>Currículos
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/contato" class="nav-link nav-link-mobile">
              <i class="fas fa-angle-double-right arrow mr-10 hide-icon"></i>Contato
            </router-link>
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <!-- Tradutor -->
          <div class="tradutor" id="google_translate_element"></div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>

</template>

<script>
export default {
  data() {
    return {
      servicos: [],
      informacoes: [],
      textoFooter: '',

      // Sociais
      facebookLink: '',
      linkedinLink: '',
      instagramLink: '',
    }
  },
  created() {

    //----------------- Facebook 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/facebook")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.facebook = retornoAPI     
      this.facebookLink =  this.facebook[0].nome
    });
    //----------------- Linkedin 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/linkedin")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.linkedin = retornoAPI     
      this.linkedinLink =  this.linkedin[0].nome
    });
    //----------------- Facebook 
    this.$http
    .get(this.$apiUrl + "/institucional/telas/instagram")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.instagram = retornoAPI     
      this.instagramLink =  this.instagram[0].nome
    });
  }
}
</script>